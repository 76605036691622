<template>
  <div>
    <div class="header_container">
      <!--左边区域-->
      <div class="left_box">
        <!--logo图片-->
        <div class="logo_box" v-if="!isCollapsed">
          <img :src="logoUrl" width="110" height="19" :alt="altTitle"/>
          <span class="logo_box_title">{{ altTitle }}</span>
        </div>
        <!--展开与收起侧边栏按钮-->
        <Icon type="md-menu" size="26" @click.native="toggleClick" v-if="showNav" class="toggleMenu"></Icon>
        <!--顶部导航菜单-->
        <Menu mode="horizontal" theme="light" :active-name="topNavActiveName" class="reset_menu_styles"
          v-if="system === 'supplier' && showNav" @on-select="selectMenu" ref="horizontalMenu">
          <MenuItem name="operationManage">
            <span class="title">{{ $t('key1000017') }}</span>
          </MenuItem>
          <MenuItem name="warehouseManage" v-if="isWarehouseManagement === 'Y'">
            <span class="title">{{ $t('key1000018') }}</span>
          </MenuItem>
        </Menu>
      </div>
      <!--订单超时未发提示-->
      <div class="timeout_box" v-if="system === 'supplier' && overTimeNum>0">
        <Icon type="md-warning" size="21" color="#D9001B" class="mr5"/>
        <div class="flex align-items-center pt2">
          <span>{{ $t('key1000014') + overTimeNum + $t('key1000015') }}</span>
          <span class="cursor" style="text-decoration: underline;" @click="handleTimedSentData('all')">
          {{ $t('key1000016') }}
        </span>
        </div>
      </div>
      <!--右边区域-->
      <div class="right_box">
        <!--右边用户信息-->
        <div class="user_info_box">
          <!--消息提醒-->
          <Poptip trigger="hover" :transfer="false" class="message_reminder_box" width="320" padding="0" v-if="messageReminderList.length >0">
            <Badge :count="unReadCount" :offset="[12,-8]">
              <Icon type="ios-notifications-outline" color="#fff" size="24" class="cursor" @click="lookMoreBtn"></Icon>
            </Badge>
            <template #content>
              <div v-show="poptipTalg">
                <ul class="message_reminder_list">
                  <li class="message_reminder_item" v-for="(item, index) in messageReminderList" :key="index" @click="viewMessageBtn(item)">
                    <Icon type="md-mail" size="20" color="#fff" class="message_icon"/>
                    <div class="message_line">
                      <h3 class="title">{{ item.title }}</h3>
                      <p class="time">{{ item.timer }}</p>
                    </div>
                  </li>
                  <li class="message_reminder_item more_style" v-if="messageReminderList.length >=5" @click="lookMoreBtn">{{ $t('key1000019') }}</li>
                </ul>
              </div>
            </template>
          </Poptip>
          <Icon type="ios-notifications-outline" color="#fff" size="24" v-else class="mr15 font-weight-bold cursor" @click="lookMoreBtn"></Icon>
          <!--语种-->
          <Dropdown @on-click="changeLanguage" class="mr15 languages">
            <img :src="flagPath" width="30" height="20" class="cursor" alt="">
            <template #list>
              <Dropdown-menu>
                <Dropdown-item v-for="(item, index) in languageList" :name="item.code" :key="index">
                  <img :src="item.iconPath" width="30" height="20" class="cursor mr10" alt="">
                  <span class="font-size-12">{{ item.title }}</span>
                  <Icon class="ml8" type="md-checkmark" size="18" color="#FD5425" v-if="flagPath == item.iconPath"/>
                </Dropdown-item>
              </Dropdown-menu>
            </template>
          </Dropdown>
          <!--时区-->
          <Poptip placement="bottom" class="time_zone mr15">
            <div class="text">UTC{{ UTCtimezoom }}</div>
            <template #content>
              <div>
                <div class="timer_box">
                  <span>{{ $t('key1000020') }}</span> <Select v-model="timeModal" filterable style="width:250px;" @on-change="changeTimezoom">
                  <Option v-for="(item,index) in timezoomList" :value="item.utcId" :key="index">{{ item.text }}</Option>
                </Select>
                </div>
                <div class="timevalue">{{ $t('key1000021') }}{{ nowTime }}</div>
                <div class="timevalue">{{ $t('key1000022') }}{{ UTCtime }}</div>
              </div>
            </template>
          </Poptip>
          <!--登出-->
          <Dropdown @on-click="logout" class="mr15">
            <div class="icon_box">
              <span class="iconfont icon-yonghu">&#xe713;</span> <span class="text_name">{{ userInfo.userName }}</span>
            </div>
            <template #list>
              <Dropdown-menu>
                <!--登出-->
                <Dropdown-item name="signOut">
                  <div class="logout_item">
                    <Icon type="md-log-out" class="icon_logout"></Icon>
                    <span class="text">{{ $t('key1000023') }}</span>
                  </div>
                </Dropdown-item>
              </Dropdown-menu>
            </template>
          </Dropdown>
          <!--通途客户编号-->
          <span class="user_info mr15">{{ merchantText + userInfo.merchantId }}</span>
          <!--基础功能设置的入口-->
          <Icon
            v-if="system === 'yms'"
            type="md-settings"
            size="19"
            color="#fff"
            @click="switchBtn"
            style="position: relative; top: -2px; cursor: pointer;"/>
        </div>
      </div>
    </div>
    <!--侧边栏设置区域-->
    <functionSettings ref="settings" @updateData="updateData"></functionSettings>
    <!--超时订单处理弹窗-->
    <overtimeOrderProcessingModal ref="overtimeOrderProcess"></overtimeOrderProcessingModal>
  </div>
</template>
<script>
import { aliasb039bd6e4486415fba0a6b42f8cd95d1 } from '@/customFolder/customVueAlias.js';

import Mixin from '@/components/mixin/common_mixin';
import * as timezoom from '@/utils/timezooms';
import {getToken, removeToken} from '@/utils/cookie';
import api from '@/api/';
import functionSettings from "@/components/common/functionSettings";
import overtimeOrderProcessingModal from "@/components/common/overtimeOrderProcessingModal";
import {verifyLoginTicket, errorUrlHandel, deleteTicket, commonSessionStorage} from "@/utils/common";

export default {
  mixins: [Mixin],
  props: {
    isCollapsed: {
      type: Boolean,
      default: false
    },
    showNav: { // 默认开启自定义的导航的模块
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      logoUrl: require('@/assets/images/logo-white.png'),
      flagPath: commonSessionStorage.getItem('selectLang') || '',
      timeModal: '',
      timezoomList: timezoom.timeList,
      nowTime: '',
      UTCtime: '',
      poptipTalg: true,
      topNavActiveName: 'operationManage', // 默认选中的顶部导航菜单
      overTimeNum: localStorage.getItem('overTimeNum') || 0,
      isPageVisible: true,
      timedTasksTimer: null,
    };
  },
  computed: {
    // 获取当前登录用户的信息
    userInfo() {
      if (this.$store.state.erpConfig) {
        return this.$store.state.erpConfig.userInfo;
      } else {
        return '';
      }
    },
    // 获取时区
    UTCtimezoom() {
      let timeZone = this.$store.state.erpConfig.timeZone;
      if (timeZone && this.timezoomList) {
        let timezoom = '';
        this.timezoomList.forEach(i => {
          if (i.utcId === timeZone) {
            timezoom = i.offset;
          }
        });
        timezoom = timezoom >= 0 ? '+' + timezoom : timezoom;
        if (localStorage.getItem('timezoom') !== timezoom) {
          localStorage.setItem('timezoom', timezoom);
        }
        return timezoom;
      } else {
        return this.$uDate.getDefaultTimezoom();
      }
    },
    // logo 标识名称
    altTitle() {
      let text = '';
      let pathname = window.location.pathname;
      let systemList = [
        {title: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000024'), pathname: '/yms.html'},
        {title: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000025'), pathname: '/supplier.html'},
        {title: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000026'), pathname: '/distributor.html'}
      ];
      systemList.map((item) => {
        if (pathname.includes(item.pathname)) {
          text = item.title;
        }
      });
      return text;
    },
    // 未读消息数量
    unReadCount() {
      return this.$store.state.unreadTotal;
    },
    // 未读消息数据
    messageReminderList() {
      return this.$store.state.unreadData;
    },
    // 供应商系统仓储管理模块是否开启
    isWarehouseManagement() {
      return this.$store.state.isWarehouseManagement;
    },
    merchantText() {
      return this.system === 'supplier' ? aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000027') : aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000028');
    }
  },
  mounted() {
    document.addEventListener('visibilitychange', this.handleVisibilityChange);
  },
  created() {
    let v = this;
    v.getTimedSentData();
    v.getLocalTime();
    v.getUTC();
    v.getUserSettingLanguage(v.userInfo).then((name) => {
      v.systemDefaultLanguage(name);
    });
    v.getSupplierWarehouseSetting(false);
    v.initWebSocket();
  },
  methods: {
    // 判断当前页面标签是否处于活跃状态
    handleVisibilityChange() {
      this.isPageVisible = !document.hidden;
    },
    // 接收websocket的数据
    initWebSocket() {
      let v = this;
      let pathname = window.location.pathname;
      let url = '';
      if (pathname.includes('/yms.html')) {
        url = api.get_ymsStationNotification_lastUnRead;
      } else if (pathname.includes('/supplier.html')) {
        url = api.get_ymsSupplierStationNotification_lastUnRead;
      }
      v.getMessageNotificationData(url);
      console.log('---v.$webSocket.socket-----', v.$webSocket.socket)
      v.$webSocket.socket.onmessage = function (e) {
        v.$webSocket.heartReset().heartStart();
        let receiveData = '';
        if (e) {
          try {
            receiveData = JSON.parse(e.data);
          } catch (e) {
            receiveData = null;
          }
          if (receiveData && receiveData.type === 'notice') {
            v.getMessageNotificationData(url);
          }
        }
      };
    },
    // 展开与收起侧边栏菜单
    toggleClick() {
      this.$emit('collapsedSider', !this.isCollapsed);
    },
    // 退出登录
    logout(value) {
      if (value === 'signOut') {
        let v = this;
        v.axios.get(api.get_logout + getToken(), null, {loading: true, loadingText: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000029')}).then(response => {
          if (response.data.code === 0) {
            removeToken().then(() => {
              setTimeout(() => {
                let url = deleteTicket(window.location.href);
                url = errorUrlHandel(url);
                window.location.href = response.data.others.logOutUrl + encodeURIComponent(url);
              }, 1500);
            });
          }
        });
      }
    },
    // 切换语种
    changeLanguage(lang) {
      let v = this;
      v.axios.put(api.put_erpCommonSettingParam_cutoverLang + lang).then(response => {
        if (response.data.code === 0) {
          v.languageList.map((item) => {
            if (item.code === lang) {
              v.flagPath = item.iconPath;
              commonSessionStorage.setItem('flagPath', item.iconPath);
              localStorage.setItem('setLanguages', item.code);
              localStorage.setItem('lang', item.name);
            }
          });
          setTimeout(() => {
            window.location.reload();
          }, 500);
        }
      });
    },
    // 设置时区
    setTime() {
      let v = this;
      return new Promise(resolve => {
        v.axios.put(api.put_updateTimeZone, {
          timeZoneId: v.timeModal
        }, {loading: true}).then(res => {
          if (res.data.code === 0) {
            resolve();
          }
        });
      });
    },
    // 选取时区
    changeTimezoom() {
      let v = this;
      let timeData;
      if (v.timeModal >= 0) {
        timeData = '+' + v.timeModal;
      } else {
        timeData = v.timeModal;
      }
      localStorage.setItem('timezoom', timeData);
      v.$store.commit('timezoomChange', timeData);
      v.$uDate.getNowTime();
      v.setTime().then(() => {
        window.location.reload();
      });
    },
    // 获取当前的时间
    getLocalTime() {
      let v = this;
      v.timer = setInterval(function () {
        v.nowTime = v.$uDate.getCurrentTime('fulltime');
      }, 1000);
    },
    // 获取世界标准时间
    getUTC() {
      let v = this;
      v.timer = setInterval(function () {
        v.UTCtime = v.$uDate.getUniversalTime(new Date().getTime(), 'fulltime');
      }, 1000);
    },
    // 打开功能设置
    switchBtn() {
      this.$refs.settings.functionSettingsDrawer = true;
    },
    // 设置功能后，重新渲染页面
    updateData() {
      let key = new Date().getTime();
      this.$emit('updateMenu', key);
    },
    // 查看当前消息
    viewMessageBtn(item) {
      let v = this;
      let pathname = window.location.pathname;
      let url = '';
      let apiUrl = '';
      if (pathname.includes('/yms.html')) {
        url = api.get_ymsStationNotification_lastUnRead;
        apiUrl = api.post_ymsStationNotification_batchRead;
      } else if (pathname.includes('/supplier.html')) {
        url = api.get_ymsSupplierStationNotification_lastUnRead;
        apiUrl = api.post_ymsSupplierStationNotification_batchRead;
      }
      v.axios.post(apiUrl, [item.ymsStationNotificationId], {loading: true}).then(response => {
        if (response.data.code === 0) {
          v.$router.push({
            path: '/messageMailboxes',
            query: {
              ymsReceiveStationNotificationLogId: item.ymsReceiveStationNotificationLogId
            }
          });
          v.getMessageNotificationData(url);
          v.poptipTalg = false;
          setTimeout(() => {
            v.poptipTalg = true;
          }, 500);
        }
      });
    },
    // 查看更多消息
    lookMoreBtn() {
      let v = this;
      v.$router.push('/messageCenter');
      v.poptipTalg = false;
      setTimeout(() => {
        v.poptipTalg = true;
      }, 500);
    },
    // 切换顶部导航菜单
    selectMenu(name) {
      let v = this;
      v.topNavActiveName = name;

      function unpdateSet() {
        localStorage.setItem('topNavActiveName', name);
        setTimeout(() => {
          v.$parent['$parent'].$refs['leftComponent'].setMenuData(name);
          v.$parent['$parent'].$refs['breadcrumbBox'].initFun(name);
        }, 500);
      }

      if (name === 'warehouseManage') {
        v.getSupplierWarehouseSetting().then((val) => {
          if (val) {
            unpdateSet();
          }
        })
      } else {
        unpdateSet();
      }
    },
    // 获取超时未发的数据
    getTimedSentData() {
      // 首次登录的时候，如果有超时未发的数据且有供应商订单列表就弹窗出标记缺货的弹窗
      let v = this;
      if (v.system === 'supplier') {
        v.axios.post(api.post_ymsSupplierUserInfo_queryOperByMenu + 'supplier_supplierOrderList' + `?subsystemCode=yms-supplier-service`).then(response => {
          if (response.data.code === 0) {
            let data = response.data.datas || [];
            if (data.length > 0 && data.includes('packageInfo_queryPackageInfoOrder')) {
              v.handleTimedTasks('all');
              verifyLoginTicket().then((val) => {
                if (val) {
                  v.handleTimedSentData().then((data) => {
                    if (data.length > 0) {
                      v.overTimeNum = data.length;
                      v.$store.commit('overTimeNum', data.length);
                      v.$refs['overtimeOrderProcess'].initOvertimeOrderProcessingData('timeout', data, 1)
                    } else {
                      v.overTimeNum = 0;
                      v.$store.commit('overTimeNum', 0);
                    }
                  });
                }
              });
            } else {
              v.overTimeNum = 0;
            }
          }
        });
      }
    },
    // 处理超时未发的数据
    handleTimedSentData(type) {
      let v = this;
      return new Promise((resolve) => {
        let query = [];
        v.overTimeNum = 0;
        localStorage.setItem('overTimeNum', 0);
        v.axios.post(api.post_packageInfo_queryOvertimeUnsentList, query).then(response => {
          if (response.data.code === 0) {
            let data = response.data.datas || [];
            if (data.length > 0) {
              let newData = data.slice(0, 100);
              v.overTimeNum = newData.length;
              localStorage.setItem('overTimeNum', v.overTimeNum);
              if (type === 'all') {
                v.$refs['overtimeOrderProcess'].initOvertimeOrderProcessingData('timeout', newData, 1)
              }
              resolve(newData);
            } else {
              resolve([]);
            }
          }
        });
      });
    },
    // 定时任务，每隔10分钟调接口查询超时未发的数据
    handleTimedTasks(value) {
      let v = this;
      if (['/login', '/logout'].includes(v.$route.path)) {
        clearInterval(this.timedTasksTimer);
      } else {
        v.timedTasksTimer = setInterval(() => {
          if (v.isPageVisible) {
            v.handleTimedSentData(value);
          }
        }, 10 * 60 * 1000);
      }
    }
  },
  watch: {
    '$store.state.changeTopMenu': {
      handler(val) {
        if (val) {
          this.selectMenu('warehouseManage')
        }
      },
      deep: true
    },
    isPageVisible: {
      handler(val) {
        if (val) {
          let name = localStorage.getItem('topNavActiveName');
          if (name) {
            this.selectMenu(name);
          }
        }
      },
      deep: true
    }
  },
  components: {
    functionSettings,
    overtimeOrderProcessingModal
  }
};
</script>

<style lang="less" scoped>
.header_container {
  flex-wrap: wrap;
  align-items: center;

  .right_box .user_info_box {
    display: flex;
    align-items: center;

    .message_reminder_box {
      margin-right: 25px;
      position: relative;
      top: 5px;
      cursor: pointer;
      line-height: 50px;

      .ivu-icon-ios-notifications-outline {
        font-weight: bold;
      }

      .message_reminder_list {
        width: 100%;

        .message_reminder_item {
          display: flex;
          align-items: center;
          justify-content: space-between;
          border-bottom: 1px solid #e8eaec;
          padding: 12px;
          cursor: pointer;

          .message_icon {
            width: 32px;
            height: 32px;
            border-radius: 50%;
            background-color: #FE5D58;
            display: flex;
            justify-content: center;
            align-items: center;
          }

          .message_line {
            width: 250px;

            .title {
              width: 250px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              word-wrap: break-word;
              word-break: break-all;
              color: #000;
              font-weight: bold;
              font-size: 13px;
            }

            .time {
              margin-top: 4px;
              font-size: 12px;
              color: #BCBCBC;
            }
          }

          &:last-child {
            border-bottom: none;
          }

          &:hover {
            background-color: #f0faff;
          }
        }

        .more_style {
          color: #333;
          display: flex;
          justify-content: center;
          font-size: 14px;
          font-weight: bold;
        }
      }
    }
  }

  .left_box {
    /deep/ .reset_menu_styles {
      background: initial;
      height: 50px;
      line-height: 50px;

      &:after {
        height: 0;
      }

      .ivu-menu-item {
        color: #fff;
        padding: 0;
        border-bottom: none;

        &:hover {
          border-bottom: none;
        }

        .title {
          margin: 0 30px;
          display: inline-block;
          position: relative;
        }
      }

      .ivu-menu-item-active {
        background-color: #fff;
        color: #113F6D;
        border-bottom: none;

        &:after {
          content: ' ';
          height: 2px;
          background-color: #113F6D;
          width: 76%;
          display: inline-block;
          position: absolute;
          bottom: 4px;
          left: 50%;
          transform: translateX(-50%);
        }
      }
    }
  }

  .timeout_box {
    height: 32px;
    line-height: 32px;
    background-color: #fff;
    border-radius: 5px;
    overflow: hidden;
    font-size: 14px;
    color: #D9001B;
    padding: 0px 30px 0 10px;
    display: flex;
    align-items: center;
  }
}
</style>
